import { Grid, TextInput, Text, Input, Paper, Avatar } from '@mantine/core';
import { useMedplum } from '@medplum/react';
import { Binary } from '@medplum/fhirtypes';
import { IconChevronDown } from '@tabler/icons-react';
import countryCode from '../countryCode.json';
import { useEffect, useState } from 'react';

interface organizationInfoProps {
  orgListResponse?: any;
  onUpdateOrgInfo: (formData: any) => void;
}
const OrganizationPersonalInfo: React.FC<organizationInfoProps> = ({ orgListResponse, onUpdateOrgInfo }) => {
  const [formData, setFormData] = useState<any>({
    organizationName: orgListResponse?.organizationName,
    street: orgListResponse?.street,
    city: orgListResponse?.city,
    pincode: orgListResponse?.pincode,
    state: orgListResponse?.state,
    phoneNo: orgListResponse?.phoneNo,
    countryCode: orgListResponse?.countryCode,
    image: orgListResponse?.image,
    imageUrl: orgListResponse?.image,
    imageTitle: orgListResponse?.imageTitle,
    accountId: orgListResponse?.accountId,
    locationId: orgListResponse?.locationId,
    ehrOid: orgListResponse?.ehrOid,
  });
  const medplum = useMedplum();

  useEffect(() => {
    onUpdateOrgInfo(formData);
  }, [formData, onUpdateOrgInfo]);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleFileChange = (files: any) => {
    const file = files.target.files[0];
    setFormData((prevData: any) => ({
      ...prevData,
      image: file,
    }));
    setFormData((prevData: any) => ({
      ...prevData,
      imageTitle: file.name,
    }));
    medplum
      .createBinary(file, file.name, file.type, () => {})
      .then((binary: Binary) => {
        setFormData((prevData: any) => ({
          ...prevData,
          imageUrl: binary.url,
        }));
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };
  return (
    <>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Organization Name</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.organizationName}
            placeholder="Organization Name"
            onChange={handleInputChange}
            name="organizationName"
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Address<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput value={formData?.street} name="street" placeholder="Street" onChange={handleInputChange} />
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput value={formData?.city} name="city" placeholder="City" onChange={handleInputChange} />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}></Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput onChange={handleInputChange} placeholder="State" value={formData?.state} name="state" />
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.pincode}
            placeholder="PostalCode"
            name="pincode"
            onChange={handleInputChange}
            maxLength={6}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Phone no<span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <Input
            component="select"
            rightSection={<IconChevronDown size={14} stroke={1.5} />}
            name="countryCode"
            value={formData.countryCode}
            onChange={handleSelectChange}
          >
            <option>Select</option>
            {countryCode.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </Input>
        </Grid.Col>
        <Grid.Col span={12} lg={4.5}>
          <TextInput
            value={formData?.phoneNo}
            placeholder="Phone No"
            name="phoneNo"
            onChange={handleInputChange}
            maxLength={10}
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Account Id <span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.accountId}
            placeholder="Account Id"
            onChange={handleInputChange}
            name="accountId"
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>
            Location Id <span style={{ color: 'red' }}>*</span>
          </Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput
            value={formData?.locationId}
            placeholder="Location Id"
            onChange={handleInputChange}
            name="locationId"
          />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>EHR OID</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={9}>
          <TextInput value={formData?.ehrOid} placeholder="EHR OID" onChange={handleInputChange} name="ehrOid" />
        </Grid.Col>
      </Grid>
      <Grid sx={{ marginBottom: '15px', alignItems: 'center' }}>
        <Grid.Col span={12} lg={3}>
          <Text style={{ fontSize: '14px', color: '#272D41' }}>Logo</Text>
        </Grid.Col>
        <Grid.Col span={12} lg={8}>
          <Paper>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
              {formData.imageUrl ? (
                <div>
                  <img src={formData.imageUrl} alt={formData.imageTitle} style={{ width: '70%' }} />
                </div>
              ) : (
                <div>
                  <Avatar
                    src="../../img/dashboard-assets/male-profile-icon.svg"
                    size={120}
                    sx={{ background: '#EFF7FF !important' }}
                  />
                </div>
              )}
              <label className="file-input-label">
                <input
                  type="file"
                  onChange={(event) => handleFileChange(event)}
                  accept=".jpg, .jpeg, .png"
                  className="file-input"
                />
                Upload logo
              </label>
            </div>
          </Paper>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default OrganizationPersonalInfo;
