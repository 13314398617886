/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useEffect, useState } from 'react';
import { Grid, Box, Card, Button, Divider } from '@mantine/core';
import { OrganizationForm } from './OrganizationForm';
import { useNavigate } from 'react-router-dom';
import { useMedplum } from '@medplum/react';
import { getOrganization, updateOrganization, createOrganizations } from '../../fhirApi';
import { showNotification } from '@mantine/notifications';

export const OrganizationEdit = () => {
  const OrganizationSample = [
    {
      resourceType: 'Organization',
      name: '',
      meta: {
        versionId: '',
        lastUpdated: '',
      },
      id: '',
      partOf: {
        reference: '',
        display: '',
      },
      telecom: [
        {
          system: 'phone',
          value: '',
        },
      ],
      address: [
        {
          use: 'work',
          type: 'physical',
          line: [],
          city: '',
          state: '',
          postalCode: '',
        },
      ],
      contact: [
        {
          name: {
            use: 'official',
            given: [],
            family: '',
          },
          telecom: [
            {
              system: 'email',
              use: 'work',
              value: '',
            },
            {
              system: 'phone',
              use: 'work',
              value: '',
            },
          ],
        },
      ],
      extension: [
        {
          url: 'http://organization.image',
          valueString: '',
        },
        {
          url: 'http://organization.ehr',
          valueString: '',
        },
      ],
    },
  ];
  const organizationUrl = window.location.href.split('/')[3];
  const orgId = window.location.href.split('/')[4];
  const navigate = useNavigate();
  const medplum = useMedplum();
  const [orgList, setOrgList] = useState<any>();
  const [formDataFromEditOrg, setFormDataFromEditOrg] = useState<any | null>(null);
  const [orgListResponse, setOrgListResponse] = useState<any>();
  useEffect(() => {
    if (organizationUrl === 'edit-organization') {
      getOrganization(medplum, orgId)
        .then((response: any) => {
          setOrgList(response);
          const orgResponse: any = {
            organizationName: response.data.name || '',
            street: '',
            city: '',
            pincode: '',
            state: '',
            phoneNo: '',
            countryCode: '',
            image: '',
            language: '',
            plan: '',
            licenses: '',
            firstName: '',
            lastName: '',
            email: '',
            contactNo: '',
            contactCountryCode: '',
            ehr: '',
            ehrFields: [],
            accountId: '',
            locationId: '',
            ehrOid: '',
            //sideBarColor: '',
          };

          response?.data?.address?.forEach((e: any) => {
            orgResponse.city = e.city;
            orgResponse.pincode = e.postalCode;
            orgResponse.state = e.state;
            orgResponse.street = e.line[0];
          });

          if (response.data?.contact) {
            response.data?.contact.map((element: any) => {
              orgResponse.firstName = element.name.given[0];
              orgResponse.lastName = element.name.family;
              element.telecom.map((e: any) => {
                if (e.system === 'email') {
                  orgResponse.email = e.value;
                } else if (e.use === 'work') {
                  orgResponse.contactCountryCode = e.value.split('-')[0];
                  orgResponse.contactNo = e.value.split('-')[1];
                }
              });
            });
          }
          if (response.data?.contact) {
            response.data?.telecom.map((e: any) => {
              orgResponse.phoneNo = e.value;
              orgResponse.countryCode = e.value.split('-')[0];
              orgResponse.phoneNo = e.value.split('-')[1];
            });
          }
          if (response?.data?.extension) {
            const ehrExtension = response.data.extension.find(
              (ext: { url: string; valueString?: string; accountId?: string; locationId?: string; ehrOid?: string }) =>
                ext.url === 'http://organization.ehr'
            );
            if (ehrExtension) {
              orgResponse.accountId = ehrExtension.accountId;
              orgResponse.locationId = ehrExtension.locationId;
              orgResponse.ehrOid = ehrExtension.ehrOid;
            }
          }

          response?.data?.extension?.forEach((e: any) => {
            if (e.ehr) {
              orgResponse.ehr = e.ehr;
              const generateNewData = (type: any, sourceData: any) => {
                switch (type) {
                  case 'provider':
                    return { ...sourceData, type: 'provider' };
                  case 'backendSystem':
                    return {
                      baseUrl: sourceData.baseUrl,
                      clientId: sourceData.clientId,
                      clientSecret: sourceData.clientSecret,
                      ehr: sourceData.ehr,
                      fhirBasePath: sourceData.fhirBasePath,
                      privateKey: sourceData.privateKey,
                      siteId: sourceData.siteId,
                      tokenUrl: sourceData.tokenUrl,
                      type: sourceData.type,
                    };
                  case 'patient':
                    return {
                      apiUrl: sourceData.apiUrl,
                      authorizationUrl: sourceData.authorizationUrl,
                      baseUrl: sourceData.baseUrl,
                      clientId: sourceData.clientId,
                      clientSecret: sourceData.clientSecret,
                      ehr: sourceData.ehr,
                      redirectURI: sourceData.redirectURI,
                      scope: sourceData.scope,
                      tokenUrl: sourceData.tokenUrl,
                      type: sourceData.type,
                    };
                  default:
                    return null;
                }
              };
              orgResponse.ehrFields.push({ id: e.id, formData: generateNewData(e.type, e) });
            } else if (e.url === 'http://organization.image') {
              orgResponse.image = e.valueString;
            }
          });

          setOrgListResponse(orgResponse);
        })
        .catch((error: any) => {
          console.error('Error fetching data:', error);
        });
    }
  }, []);
  const submitForm = () => {
    createPayloadOrganization();
  };
  const createPayloadOrganization = () => {
    OrganizationSample.map((element: any) => {
      element.name = formDataFromEditOrg.organizationName;
      if (organizationUrl === 'edit-organization') {
        element.id = orgId;
        element.partOf.reference = `Organization/${orgId}`;
        element.partOf.display = formDataFromEditOrg.organizationName;
        element.meta.versionId = orgList.data.meta.versionId;
        element.meta.lastUpdated = orgList.data.meta.lastUpdated;
        element.identifier = orgList.data.identifier;
      }
      element?.address.map((e: any) => {
        e.city = formDataFromEditOrg.city;
        e.postalCode = formDataFromEditOrg.pincode;
        e.state = formDataFromEditOrg.state;
        e.line[0] = formDataFromEditOrg.street;
      });
      element?.telecom.map((e: any) => {
        e.value = `${formDataFromEditOrg.countryCode}-${formDataFromEditOrg.phoneNo}`;
      });
      element.contact.map((conatct: any) => {
        conatct.name.given[0] = formDataFromEditOrg.firstName;
        conatct.name.family = formDataFromEditOrg.lastName;
        conatct.telecom.map((e: any) => {
          if (e.system === 'email') {
            e.value = formDataFromEditOrg.email;
          } else if (e.use === 'work') {
            e.value = `${formDataFromEditOrg.contactCountryCode}-${formDataFromEditOrg.contactNo}`;
          }
        });
      });
      element?.extension.map((e: any) => {
        if (e.url === 'http://organization.image') {
          e.valueString = formDataFromEditOrg.imageUrl;
        }
      });
      element?.extension.map(
        (e: { url: string; valueString?: string; accountId?: string; locationId?: string; ehrOid?: string }) => {
          if (e.url === 'http://organization.ehr') {
            e.accountId = formDataFromEditOrg.accountId;
            e.locationId = formDataFromEditOrg.locationId;
            e.ehrOid = formDataFromEditOrg.ehrOid;
          }
        }
      );
      element?.extension.map((e: any) => {
        if (e.url === 'http://organization.ehr') {
          formDataFromEditOrg.ehrFields.forEach((ehr: any) => {
            const { formData } = ehr;
            const commonFieldsToDelete = ['siteId', 'fhirBasePath'];
            const backendSystemFieldsToDelete = ['apiUrl', 'authorizationUrl', 'redirectURI', 'scope'];
            const patientFieldsToDelete = ['privateKey'];

            const fieldsToDelete: any = {
              backendSystem: backendSystemFieldsToDelete,
              provider: commonFieldsToDelete,
              patient: [...commonFieldsToDelete, ...patientFieldsToDelete],
            };

            const type = formData.type;
            fieldsToDelete[type]?.forEach((field: any) => delete formData[field]);

            const existingIndex = orgList?.data.extension.findIndex((ext: any) => ext.type === type);
            if (existingIndex !== undefined && existingIndex !== -1) {
              // If the object with the same type exists, update its properties
              formData.id = ehr.id;
              element.extension[existingIndex] = { ...element.extension[existingIndex], ...formData };
            } else {
              // If the object with the same type does not exist, push a new object
              formData.ehr = formDataFromEditOrg.ehr;
              formData.id = ehr.id;
              element.extension.push(formData);
            }
            //element.extension = element.extension.filter((obj: {}) => Object.keys(obj).length > 0);
          });
        }
      });
    });
    if (organizationUrl === 'create-organization') {
      createOrganization();
    } else {
      editOrganization();
    }
  };
  const createOrganization = () => {
    createOrganizations(medplum, OrganizationSample[0])
      .then((response: any) => {
        if (response) {
          let orgImage: any;
          if (response.data?.extension) {
            response.data?.extension.map((e: any) => {
              if (e.url === 'http://organization.image') {
                orgImage = e.valueString;
              }
            });
          }
          localStorage.setItem('orgImage', orgImage);
          showNotification({ color: 'green', message: 'Created successfully' });
          navigate('/Organization');
        } else {
          showNotification({ color: 'red', message: 'error' });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };

  const editOrganization = () => {
    updateOrganization(medplum, OrganizationSample[0], orgId)
      .then((response: any) => {
        if (response?.status === 200) {
          let orgImage: any;
          if (response.data?.extension) {
            response.data?.extension.map((e: any) => {
              if (e.url === 'http://organization.image') {
                orgImage = e.valueString;
              }
            });
          }
          localStorage.setItem('orgImage', orgImage);
          showNotification({ color: 'green', message: 'Updated successfully' });
          navigate('/Organization');
        } else {
          showNotification({ color: 'red', message: 'error' });
        }
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleChildFormUpdate = (formData: FormData) => {
    // Receive form data from the child component and store it in the parent
    setFormDataFromEditOrg(formData);
  };
  const isSubmitDisabled =
    !formDataFromEditOrg?.city ||
    !formDataFromEditOrg?.state ||
    !formDataFromEditOrg?.street ||
    !formDataFromEditOrg?.phoneNo ||
    !formDataFromEditOrg?.firstName ||
    !formDataFromEditOrg?.lastName ||
    !formDataFromEditOrg?.email ||
    !formDataFromEditOrg?.contactNo ||
    !formDataFromEditOrg?.accountId ||
    !formDataFromEditOrg?.locationId;
  return (
    <div>
      <Box px="lg" py="sm" sx={{ paddingBottom: '0px !important' }}>
        <Grid mb="md">
          <Grid.Col span={12} lg={9}>
            <Card withBorder radius="md" sx={{ padding: '0px !important', boxShadow: '0px 1px 2px 0px #0000001A' }}>
              <Card.Section p="lg" sx={{ padding: '2rem 2rem !important' }}>
                {organizationUrl === 'edit-organization' && orgListResponse && (
                  <OrganizationForm orgListResponse={orgListResponse} onUpdateFormData={handleChildFormUpdate} />
                )}
                {organizationUrl === 'create-organization' && (
                  <OrganizationForm orgListResponse={orgListResponse} onUpdateFormData={handleChildFormUpdate} />
                )}
                <Divider my="sm" />
                <Grid sx={{ justifyContent: 'end', margin: '20px 0' }}>
                  <Button
                    sx={{
                      border: '1.5px solid #3673B6',
                      background: 'transparent',
                      padding: '8px 60px',
                      color: '#3673B6',
                      fontWeight: 600,
                      marginRight: '10px',
                      '&:hover': {
                        background: 'transparent',
                      },
                    }}
                    onClick={() => navigate(`/Organization`)}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      background: '#3673B6',
                      padding: '8px 60px',
                      fontWeight: 600,
                      '&:hover': {
                        background: '#3673B6',
                      },
                    }}
                    onClick={submitForm}
                    disabled={isSubmitDisabled}
                  >
                    Save
                  </Button>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </Grid>
      </Box>
    </div>
  );
};
