/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable array-callback-return */
/* eslint-disable no-debugger */
import {
  Box,
  Card,
  Grid,
  createStyles,
  Text,
  Title,
  Center,
  Button,
  Modal,
  Radio,
  MultiSelect,
  Checkbox,
  SelectItem,
} from '@mantine/core';
import { useEffect, useState } from 'react';
// import Header from '../TitleComponent';
import { PatientDetails } from './PatientDetails';
import { fetchPatientDetails, exportMedicalRecord, getPatientClinicalData } from '../../fhirApi';
import { ResourceAvatar, useMedplum } from '@medplum/react';
import { formatAddress } from '@medplum/core';
import { useParams } from 'react-router-dom';
import TitleComponent from '../../components/TitleComponent';
// import { AIInsights } from './AIInsights';
import { showNotification } from '@mantine/notifications';

const resources: SelectItem[] = [
  { value: 'MedicationRequest', label: 'Medications' },
  { value: 'Appointment', label: 'Appointments' },
  { value: 'Encounter', label: 'Encounters' },
  { value: 'Condition', label: 'Conditions' },
  { value: 'Immunization', label: 'Immunizations' },
  { value: 'DiagnosticReport', label: 'Diagnostic Reports' },
  { value: 'Observation', label: 'Observations' },
  { value: 'Procedure', label: 'Procedures' },
  { value: 'AllergyIntolerance', label: 'Allergies' },
  { value: 'CareTeam', label: 'Care Teams' },
  { value: 'CarePlan', label: 'Care Plans' },
  { value: 'FamilyMemberHistory', label: 'Family Member History' },
  { value: 'Coverage', label: 'Coverage' },
];
export interface PatientProps {
  id: string;
  name: any;
  address: any;
  telecom: any;
  gender: string;
  birthDate: string;
  identifier: any;
}

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    height: '100%',
  },

  section: {
    padding: theme.spacing.md,
  },

  footer: {
    padding: theme.spacing.md,
    borderTop: `1px solid #EEF1F6 !important`,
  },

  header: {
    padding: theme.spacing.md,
    borderBottom: `1px solid #EEF1F6 !important`,
  },

  icon: {
    padding: theme.spacing.xs,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#EDF5FF',
    height: '40px',
    width: '40px',
    borderRadius: '25px',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[5],
  },

  scrollbar: {
    overflow: 'hidden',
    '&:hover': {
      overflowY: 'auto',
      scrollbarWidth: 'thin',
      scrollbarColor: '#ccc #f3f3f3',
      '&::-webkit-scrollbar': {
        width: '8px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ccc',
        borderRadius: '8px',
        '&:hover': {
          background: '#aaa',
        },
      },
    },
  },

  label: {
    fontWeight: 400,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.sm,
  },

  table: {
    'tr:first-of-type td': {
      color: 'blue !important',
    },
  },

  tableRow: {
    padding: '30px',
  },

  highlitedRow: {
    padding: '30px',
    color: 'blue !important',
  },

  dBlock: {
    [theme.fn.smallerThan('sm')]: {
      display: 'block',
    },
  },

  customTabs: {
    '& button': {
      fontSize: '14px',
      color: '#000',
      background: '#eee',
      marginRight: '10px',
    },
    '& button[data-active]': {
      color: '#e9f3ff  ',
      background: '#1b5390 !important',
      backgroundColor: '#1b5390 !important',
    },
  },

  customTabPanel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '300px',
  },
}));

export function Patient(): JSX.Element {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const patientID = useParams().id!;
  // const [patientCalories, setPatientCalories] = useState([]);
  // const [checkAIinsights, setCheckAIinsights] = useState<boolean>(false);
  const [patientData, setPatientData] = useState<PatientProps>({
    id: '',
    name: '',
    address: {},
    telecom: [],
    gender: '',
    birthDate: '',
    identifier: {},
  });

  const [modalOpened, setModalOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState('new');
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [clinicalDataLastDateTime, setClinicalDataLastDateTime] = useState(null);
  const [includePII, setIncludePII] = useState(true);

  useEffect(() => {
    const graphqlQuery = `${
      '{ Patient(id: "' +
      patientID +
      '") { resourceType id name { given family } address { line city state country } telecom {system value} gender birthDate identifier { value } } }'
    }`;

    fetchPatientDetails(medplum, graphqlQuery)
      .then((response: any) => {
        setPatientData(response?.data.Patient as PatientProps);
      })
      .catch((error: any) => {
        console.error('Error fetching data:', error);
      });
    //calling summary API
    // getAnalyticsSummary();
  }, []);

  // const convertDateIntoDay = (date : Date) => {
  //   const dateObject = new Date(date);
  //   return dateObject.toLocaleDateString('en-US', { weekday: 'long' }).slice(0, 3);
  // }

  useEffect(() => {
    const fetchClinicalData = async () => {
      const data = await getPatientClinicalData(medplum, patientID);
      if (data) {
        setClinicalDataLastDateTime(data.data);
      }
    };

    fetchClinicalData();
  }, [medplum, patientID]);

  const formattedClinicalDataLastDateTime = clinicalDataLastDateTime
    ? new Date(clinicalDataLastDateTime).toLocaleString()
    : '';

  function getName(name: any): string {
    const firstName: string[] = [];
    name?.given?.forEach((entry: any) => {
      if (entry) {
        firstName.push(entry);
      }
    });
    const formattedFirstName = firstName.join(' ');
    const patientName = formattedFirstName
      ? formattedFirstName + (name?.family ? ' ' + name.family : '')
      : name?.family || '';
    return patientName;
  }

  const patientName = getName(patientData?.name[0]);

  // const getAnalyticsSummary = () => {
  //   analyticsSummary(medplum, patientID).then((response) => {
  //     const updatedResponse = response?.data.map((item: any) => ({
  //       ...item,
  //       name: convertDateIntoDay(item.date),
  //     }));
  //     // setPatientCalories(updatedResponse)
  //   }).catch((error) => {
  //     console.log(error)
  //   })
  // }

  const handleResourceChange = (value: string[]) => {
    setSelectedResources(value);
  };

  const downloadMedicalRecordPdf = async (patientName: string, exportType: string, isPII: boolean) => {
    const isGetOldDocFromS3 = selectedOption !== 'new';
    const resourcesToExport = selectedOption === 'new' ? selectedResources : [];

    try {
      const response = await exportMedicalRecord(medplum, patientID, resourcesToExport, isGetOldDocFromS3, exportType, isPII);
      if (response && response.data) {
        let blob;
        let fileExtension;
        let mimeType;

        if (exportType === 'FHIR') {
          mimeType = 'application/json';
          fileExtension = 'json';
        } else if (exportType === 'PDF') {
          mimeType = 'application/pdf';
          fileExtension = 'pdf';
        } else if (exportType === 'HTML') {
          mimeType = 'text/html';
          fileExtension = 'html';
        }

        blob = new Blob([response.data], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        if (exportType === 'HTML') {
          window.open(url, '_blank');
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}_MedicalRecord.${fileExtension}`;
          a.click();
        } else {
          const a = document.createElement('a');
          a.href = url;
          a.download = `${patientName}_MedicalRecord.${fileExtension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        window.URL.revokeObjectURL(url);
        showNotification({ color: 'green', message: 'Medical record exported successfully' });
        setModalOpened(false);

        // Fetch and update clinical data immediately after download
        const data = await getPatientClinicalData(medplum, patientID);
        if (data) {
          setClinicalDataLastDateTime(data.data);
        }
      } else {
        throw new Error('Response is null or does not contain data');
      }
    } catch (error) {
      console.error('Error fetching medical record:', error);
      showNotification({ color: 'red', message: 'Failed to export medical record' });
    }
  };
  return (
    <>
      <Box px="lg" pt="sm">
        <TitleComponent title={''} />
      </Box>

      <Box px="lg" pb="sm">
        <Card withBorder radius="md" className={classes.card}>
          <Card.Section className={classes.header} p="lg">
            <Center className={classes.dBlock} style={{ justifyContent: 'space-between' }}>
              <Text fw={700}>Patient Information</Text>
              <Text fw={700}>
                Medical Record No: {patientData.identifier ? patientData?.identifier[0]?.value : 'NA'}{' '}
              </Text>
            </Center>
          </Card.Section>

          <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
            <Grid columns={12}>
              <Grid.Col span={12} sm={6} lg={1}>
                <Center>
                  <ResourceAvatar value={patientData} size="xl" radius="50px" />
                </Center>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={4}>
                <Grid>
                  <Grid.Col span={12} sm={6} lg={12}>
                    <Title variant="h1" className="f-dark" fw={700}>
                      {getName(patientData?.name[0])}
                    </Title>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Date of Birth
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark">{patientData?.birthDate || 'NA'}</Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Gender
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.gender || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col span={12} sm={6} lg={4}>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.address ? formatAddress(patientData?.address?.[0] || {}) : 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Mobile
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'phone')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
                <Grid>
                  <Grid.Col span={6} sm={6} lg={4}>
                    <Text className="f-dark" fw={600}>
                      Email Address
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={6} sm={6} lg={8}>
                    <Text className="f-dark" fw={500}>
                      {patientData?.telecom?.find((t: any) => t.system === 'email')?.value || 'NA'}
                    </Text>
                  </Grid.Col>
                </Grid>
              </Grid.Col>
              <Grid.Col
                span={12}
                sm={6}
                lg={3}
                style={{ justifyContent: 'center', display: 'flex', alignItems: 'flex-end' }}
              >
                <Center style={{ justifyContent: 'center' }}>
                  <div style={{ textAlign: 'center' }}>
                    <div style={{ marginBottom: '5px' }}>
                      <Button
                        style={{
                          textDecoration: 'none',
                          color: '#fff',
                          backgroundColor: '#3673B6',
                          padding: '3px 5px',
                          fontSize: '11px',
                          fontWeight: 700,
                          borderRadius: '2px',
                          marginRight: '5px',
                          width: '53px',
                        }}
                        onClick={() => window.open(`/Patient/${patientData.id}/details`, '_blank')}
                      >
                        View
                      </Button>
                      <Button
                        style={{
                          textDecoration: 'none',
                          color: '#fff',
                          backgroundColor: '#3673B6',
                          padding: '3px 5px',
                          fontSize: '11px',
                          fontWeight: 700,
                          borderRadius: '2px',
                          marginTop: '5px',
                          width: '150px',
                        }}
                        onClick={() => setModalOpened(true)}
                      >
                        Download Medical Record
                      </Button>
                    </div>
                  </div>
                </Center>

                <Modal
                  size="lg"
                  opened={modalOpened}
                  onClose={() => {
                    setModalOpened(false);
                    setSelectedResources([]);
                  }}
                  title={<div style={{ fontSize: '20px' }}>Medical Record Summary</div>}
                >
                  <div style={{ margin: '10px 0px 0px', fontSize: '16px', fontWeight: 400, color: '#444444' }}>
                    Generate a new Medical Record Summary, or instantly download an existing one.
                  </div>
                  <Radio.Group mt={20} mb={20} name="summaryOption" value={selectedOption} onChange={setSelectedOption}>
                    <Radio
                      key="new"
                      value="new"
                      label="Generate New"
                      my="xs"
                      styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                    />
                    {formattedClinicalDataLastDateTime && (
                      <Radio
                        key="existing"
                        value="existing"
                        label={`Get summary from ${formattedClinicalDataLastDateTime}`}
                        my="xs"
                        styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                      />
                    )}
                  </Radio.Group>
                  {selectedOption === 'new' && (
                    <>
                      <MultiSelect
                        label="Select Resources"
                        placeholder="Select resources"
                        data={resources}
                        value={selectedResources}
                        onChange={handleResourceChange}
                        clearable
                        searchable
                        nothingFound="No options"
                        multiple
                        dropdownPosition="bottom"
                        style={{ marginBottom: '2rem' }}
                        styles={{
                          label: {
                            marginBottom: '0.5rem',
                          },
                        }}
                        itemComponent={({ value, label, ...others }) => (
                          <div {...others}>
                            <Checkbox
                              key={value}
                              value={value}
                              label={label}
                              checked={selectedResources.includes(value)}
                              onChange={() =>
                                handleResourceChange(
                                  selectedResources.includes(value)
                                    ? selectedResources.filter((item) => item !== value)
                                    : [...selectedResources, value]
                                )
                              }
                            />
                          </div>
                        )}
                      />
                      <Checkbox
                        label="Include Patient PII Data"
                        checked={includePII}
                        onChange={(event) => setIncludePII(event.currentTarget.checked)}
                        style={{ marginBottom: '1rem' }}
                        styles={{ label: { fontSize: '16px', fontWeight: 500 } }}
                      />
                    </>
                  )}
                  <div>
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={() => downloadMedicalRecordPdf(patientName, 'FHIR', includePII)}
                    >
                      FHIR
                    </Button>
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={() => downloadMedicalRecordPdf(patientName, 'PDF', includePII)}
                    >
                      PDF
                    </Button>
                    <Button
                      onClick={() => downloadMedicalRecordPdf(patientName, 'HTML', includePII)}
                    >
                      HTML
                    </Button>
                  </div>
                </Modal>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Box>

      <Box px="lg" py="sm">
        <PatientDetails />
      </Box>
    </>
  );
}
